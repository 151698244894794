<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" style="width:500px;margin-left:100px">
      <!-- background:#1890FF; -->
      <el-form-item label="省">
        <el-select v-model="form.Province" placeholder="省" @change="province">
          <el-option v-for="item in provinceList" :key="item.ID" :label="item.Name" :value="item.ID">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市">
        <el-select v-model="form.City" placeholder="市" @change="city">
          <el-option v-for="item in cityList" :key="item.ID" :label="item.Name" :value="item.ID">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="区">
        <el-select v-model="form.Area" placeholder="区">
          <el-option v-for="item in areaList" :key="item.ID" :label="item.Name" :value="item.ID">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="学校">
         <el-select v-model="form.School" filterable allow-create placeholder="学校" @change="school">
      <el-option
       v-for="item in schoolList"
        :key="item.ID"
        :label="item.SchoolName"
        :value="item.ID"
      >
      </el-option>
    </el-select>
      </el-form-item> -->
      <el-form-item label="类别">
        <el-select v-model="form.TypeId" placeholder="请选择学校类别">
          <el-option v-for="item in categoryList" :key="item.ID" :label="item.TypeName" :value="item.ID">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="学校">
        <el-input v-model="form.School" placeholder="请输入学校" style="width:300px"></el-input>
      </el-form-item>

      <el-form-item label="其他信息" class="other">
        <el-input v-model="form.Name" placeholder="请输入姓名"></el-input>
        <el-input v-model="form.Mobile" placeholder="请输入手机号" :maxlength="11" @blur="ChangeMobile"></el-input>
        <el-input v-model="form.Account" placeholder="请输入账号(最少五位数)" :minlength="5" @blur="verify"></el-input>
        <el-input v-model="form.Password" placeholder="请输入密码" show-password></el-input>
      </el-form-item>
      <el-button type="primary" @click="onSubmit">创建</el-button>
    </el-form>
    <!-- <div> -->
    <p class="warn" v-if="check">*账号须包含字母和数字</p>
    <!-- <p class="warnTel"  v-if="checkTel">*手机号输入有误</p> -->
    <!-- </div> -->

  </div>
</template>
<script>
  import {
    provinceCityArea,
    schoolAdd
  } from '@/api/adminApi.js'
  import md5 from 'js-md5'
  export default {
    data() {
      return {
        form: {
          Province: '', // 省份
          City: '', // 城市
          Area: '', // 区域
          School: '', // 学校
          TypeId: '', // 学校类别
          Name: '', // 联系人
          Mobile: '', // 手机号
          Account: '', // 账号
          Password: '' // 密码
        },
        check: false, // 账号校验
        // checkTel:false, //手机号校验
        provinceList: [], // 省
        cityList: [], // 市
        cityListCopy: [], // 市 副本
        areaList: [], // 区
        areaListCopy: [], // 区 副本
        schoolList: [], // 校
        categoryList: [], // 学校类别
        Regex: /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/, // 字母数字校验
        regexs: /^1[3456789]\d{9}$/, // 手机号正则
        Telcheck: false,
        Numcheck: false
      }
    },
    mounted() { // 初始化
      this.provinceCityArea() // 总后台省市区学校接口
    },
    methods: {
      provinceCityArea() { // 总后台省市区学校接口
        provinceCityArea().then(res => {
          // console.log(res);
          this.categoryList = res.data.schoolType // 学校类别
          this.form.TypeId = res.data.schoolType[1].ID // 学校类别默认值
          this.provinceList = res.data.province // 省
          this.cityList = res.data.city // 市
          this.cityListCopy = res.data.city // 市 副本
          this.areaList = res.data.area // 区
          this.areaListCopy = res.data.area // 区
        }).catch(err => {
          console.log(err)
        })
      },
      onSubmit(e) { // 表单提交
        // console.log(this.form)
        const datas = {
          province: this.form.Province, //	省ID
          city: this.form.City, //	市ID
          area: this.form.Area, //	区ID
          schoolName: this.form.School, //	学校名称
          schoolType: this.form.TypeId, //	学校类型ID
          username: this.form.Name, // 	姓名
          tel: this.form.Mobile, //	手机号
          account: this.form.Account, // 账号
          password: md5(this.form.Password) // 密码
        }

        var arrs = Object.values(this.form).filter(item => {
          if (item !== '') {
            return true // 过滤掉为空的项
          }
        })
        // console.log(arrs.length);
        if (arrs.length == 9 && this.Telcheck && this.Numcheck) {
          // console.log('OK');
          schoolAdd(datas).then(res => {
            if (res.status.code == 1) {
              this.$message({
                message: '创建成功',
                type: 'success'
              })
              this.form = {} // 清空
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        } else if (!this.Regex.test(this.form.Account)) {
          this.$message.error('账号须包含字母和数字')
        } else if (!this.regexs.test(this.form.Mobile)) {
          this.$message.error('手机号有误')
        } else {
          this.$message.error('请完善表单')
        }
      },
      province(val) { // 省份选择
        // this.Provincetitle = this.provinceList.find(item => item.ID == id)
        // this.Provincetitle = this.Provincetitle == undefined ? this.form.Province : this.Provincetitle.Name
        // const data = {
        //   ProvinceId: id
        // }
        // this.$request.post('/api/Manage/GetCity', data).then(res => { // 市
        //   // console.log(res);
        //   this.cityList = res.data.city
        // }).catch(err => { console.log(err) })
        // console.log(val);
        this.form.City = '' // 省份变动清空城市
        this.cityList = this.cityListCopy
        this.cityList = this.cityList.filter(item => item.ProvinceId == val)
      },
      city(val) { // 城市选择
        this.form.Area = '' // 城市变动清空区域
        this.areaList = this.areaListCopy
        this.areaList = this.areaList.filter(item => item.CityId == val)
      },
      ChangeMobile() { // 输入手机号失去焦点
        if (!this.regexs.test(this.form.Mobile)) {
          this.$message.error('手机号有误')
          this.Telcheck = false
        } else {
          this.Telcheck = true
        }
        // console.log( this.Telcheck,'手机');
      },
      verify() { // 账号校验
        if (!this.Regex.test(this.form.Account)) {
          this.check = true
          this.Numcheck = false
        } else {
          this.check = false
          this.Numcheck = true
        }
        // console.log( this.Numcheck,'账号');
      }

    }

  }
</script>
<style lang="scss" scoped>
  .Addschool,
  .Addcity {
    display: flex;
    background: pink;
    align-items: center;

    input {
      width: 200px;
      height: 30px;
      outline: none;
      margin-left: 5px;
    }

    button {
      color: white;
      border: none;
      outline: none;
      margin-left: 5px;
      background: cornflowerblue;
    }
  }

  .List {
    background: orange;
    width: 300px;

    li {
      list-style: none;
    }
  }

  .warn {
    display: inline;
    color: red;
    position: relative;
    right: 100px;
    bottom: 131px;
  }

  // .warnTel{
  //   display: inline;
  //   color: red;
  //   position: relative;
  //   right: 126px;
  //   bottom: 192px;
  // }
</style>
